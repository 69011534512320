import Cookies from "js-cookie";
import { NextRouter } from "next/router";
import { VISITOR_COOKIE_NAME } from "~/components/analytics/VisitorManager";

export const apiAuthPath = (subRoute: "login" | "logout" | "signup") => {
  return `/api/auth${
    location.pathname.startsWith("/mo-crcl")
      ? "/mocrcl"
      : location.pathname.startsWith("/bdg")
      ? "/bdg"
      : location.pathname.startsWith("/ggrass")
      ? "/ggrass"
      : ""
  }/${subRoute}`;
};

export function logout(router: NextRouter) {
  Cookies.remove(VISITOR_COOKIE_NAME);
  router.push(apiAuthPath("logout"));
}

export function login(router: NextRouter) {
  router.push({
    pathname: apiAuthPath("login"),
    query: { returnTo: location.pathname },
  });
}

export function signup(
  router: NextRouter,
  returnTo: string | undefined = undefined
) {
  router.replace({
    pathname: apiAuthPath("signup"),
    query: returnTo != null ? { returnTo } : {},
  });
}
