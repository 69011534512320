import { UserProvider } from "@auth0/nextjs-auth0";
import { withErrorBoundary } from "@sentry/nextjs";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { FC, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import IdentifyManager from "~/components/analytics/IdentityManager";
import VisitorManager from "~/components/analytics/VisitorManager";
import { IntercomManager } from "~/components/layout/IntercomManager";
import ErrorView from "~/components/views/ErrorView";
import { page } from "~/lib/analytics";
import * as fbq from "~/lib/analytics/fbq";
import * as gtag from "~/lib/analytics/gtag";
import "~/styles/globals.css";

const CustomApp: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
          },
        },
      })
  );

  useEffect(() => {
    page(location.pathname + location.search);
    fbq.pageview();

    const handleRouteChange = (url: string) => {
      page(url);
      fbq.pageview();
      gtag.pageview(url);
      window.Intercom?.("update");
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <link
          rel="shortcut icon"
          href="/images/default-logo-150.png"
          type="image/png"
        />

        <title>FarmRaise</title>
      </Head>

      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MTN69T7');
        `}
      </Script>

      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
          <Toaster position="top-right" />
          <IdentifyManager />
          <IntercomManager />
          <VisitorManager />
        </QueryClientProvider>
      </UserProvider>

      <Script
        id="reddit-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};
          p.callQueue=[];
          var t=d.createElement("script");
          t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;
          var s=d.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(t,s)}}(window,document);
          rdt('init','t2_s3tcz3qe', {"optOut":false,"useDecimalCurrencyValues":true});
          rdt('track', 'PageVisit');
          `,
        }}
      />

      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="fbq-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googleoptimize.com/optimize.js?id=${gtag.GA_OPTIMIZE_ID}`}
      />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      {/* <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
            gtag('config', '${gtag.GA_ADWORDS_ID}');
          `,
        }}
      /> */}

      {/* Intercom Chat Widget */}
      <Script
        id="intercom-chat-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "${process.env.NEXT_PUBLIC_INTERCOM_WORKSPACE_ID}",
            custom_launcher_selector: ".js-launch-chat",
          };
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/yjdzyaf2';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `,
        }}
      />
    </>
  );
};

export default withErrorBoundary(CustomApp, { fallback: <ErrorView /> });
