export const GA_ADWORDS_ID = process.env.NEXT_PUBLIC_GA_ADWORDS_ID || "error";
export const GA_OPTIMIZE_ID = process.env.NEXT_PUBLIC_GA_OPTIMIZE_ID || "error";
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID || "error";

enum ConversionTag {
  Purchase = "o3pJCKjv4JICEKGazpsC",
  Subscribe = "mVf1CPOLufcCEKGazpsC",
  FreeSubscribe = "OveJCPrht8oDEKGazpsC",
}

export interface AnalyticsItem {
  item_id: string;
  item_name: string;
  coupon: string | null;
  currency: "USD";
  discount: string | null;
  price: number;
  quantity: number;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export function pageview(url: string): void {
  // window.gtag("config", GA_TRACKING_ID, {
  //   page_path: url,
  // });
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export function event({
  action,
  category,
  label,
  value,
}: {
  action: string;
  category?: string;
  label?: string;
  value?: number;
}): void {
  // window.gtag("event", action, {
  //   event_category: category,
  //   event_label: label,
  //   value,
  // });
}

export function subscribe({ value }: { value: number }): void {
  // window.gtag("event", "sign_up", {
  //   value,
  //   type: "premium",
  // });
  // window.gtag("event", "conversion", {
  //   send_to: `${GA_ADWORDS_ID}/${ConversionTag.Subscribe}`,
  //   value,
  //   currency: "USD",
  // });
}

export function freeSubscribe() {
  // window.gtag("event", "sign_up", {
  //   type: "free",
  // });
  // window.gtag("event", "conversion", {
  //   send_to: `${GA_ADWORDS_ID}/${ConversionTag.FreeSubscribe}`,
  // });
}
