import { captureException } from "@sentry/nextjs";

type Properties = Record<string, any>;

const baseUrl = "/api/proxy/v1/beacon";
const baseProps: Properties = { app: "farmer-app" };
const sendBeacon =
  typeof global.navigator !== "undefined"
    ? navigator?.sendBeacon?.bind(navigator)
    : () => {};

function trySendBeacon(url: string, data: string) {
  try {
    sendBeacon(url, data);
  } catch (error) {
    // this happens a lot. It's a brower problem, and we aren't dealing with it.
    if ((error as Error).message === "Illegal invocation") {
      return;
    }
    captureException(error);
  }
}

export function identify(userId: string, traits?: Properties): void {
  const url = `${baseUrl}/i`;
  const data = JSON.stringify({ userId, traits: { ...baseProps, ...traits } });
  trySendBeacon(url, data);
}

export function track(event: string, props?: Properties): void {
  trySendBeacon(
    `${baseUrl}/t`,
    JSON.stringify({ event, props: { ...baseProps, ...props } })
  );
}

export function page(name: string, props?: Properties): void {
  trySendBeacon(
    `${baseUrl}/p`,
    JSON.stringify({ name, props: { ...baseProps, ...props } })
  );
}
