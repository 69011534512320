export function getCookieDomain(env?: string): string {
  switch (env) {
    case "staging": {
      return ".frmrs.dev";
    }

    case "production": {
      return ".farmraise.com";
    }

    case "local":
    case "development":
    default: {
      return "";
    }
  }
}
