import { useUser } from "@auth0/nextjs-auth0";
import { FC, useEffect } from "react";
import { useUser as useFarmRaiseUser } from "../hooks/useUser";

export const IntercomManager: FC = () => {
  const { user: auth0User } = useUser();
  const { data: farmRaiseUser } = useFarmRaiseUser();

  useEffect(() => {
    if (auth0User && farmRaiseUser && farmRaiseUser.intercomUserHash) {
      window.Intercom?.("boot", {
        user_id: farmRaiseUser.id,
        email: farmRaiseUser.email,
        name: `${farmRaiseUser.firstName} ${farmRaiseUser.lastName}`,
        user_hash: farmRaiseUser.intercomUserHash,
      });
    }
  }, [auth0User, farmRaiseUser]);

  return null;
};
