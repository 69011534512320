import Cookies from "js-cookie";
import { FC, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { getCookieDomain } from "~/lib/utils/getCookieDomain";

const cookieExpiry = 365; // Let's start with a year?
export const VISITOR_COOKIE_NAME = "vid";
const isProd = process.env.NODE_ENV === "production";
const domain = getCookieDomain(process.env.NEXT_PUBLIC_APP_ENV);

/**
 * Ensures visitors have a "vid" cookie to be passed along with anonymous Segment calls,
 * like the ones sent during signup.
 */
const VisitorManager: FC = () => {
  useEffect(() => {
    if (!Cookies.get(VISITOR_COOKIE_NAME)) {
      Cookies.set(VISITOR_COOKIE_NAME, uuid(), {
        domain,
        expires: cookieExpiry,
        sameSite: "lax",
        secure: isProd,
      });
    }
  }, []);

  return null;
};

export default VisitorManager;
