import Head from "next/head";
import { FC } from "react";

const ErrorView: FC = () => {
  return (
    <>
      <Head>
        <title>An error has occurred | FarmRaise</title>
      </Head>

      <div
        data-testid="loading-page"
        className="flex h-screen w-screen items-center justify-center"
      >
        <div className="items-center">
          <div className="max-w-lg space-y-4">
            <h1 className="text-darkBlue">Oops... something went wrong</h1>
            <p>
              Looks like you have encountered an unexpected error. Our team has
              been notified of the error. Try again, and if the issues persists,
              please{" "}
              <a href="mailto:support@farmraise.us">reach out to our team.</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorView;
