export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;

export function pageview(): void {
  window.fbq("track", "PageView");
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export function event(name: string, options: Record<string, any> = {}): void {
  window.fbq("track", name, options);
}
