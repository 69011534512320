import * as Sentry from "@sentry/nextjs";
import { FC, useEffect } from "react";
import { useUser } from "~/components/hooks/useUser";
import { identify } from "~/lib/analytics";

/**
 * Checks to see if we can load the current user's ID, if we know who's logged in,
 * we need to tie them to any prior anonymous actions in Segment. This trigger may
 * need to be adjusted later, but should be sufficient for now!
 */
const IdentifyManager: FC = () => {
  const { data } = useUser();

  useEffect(() => {
    if (!data?.id) return;

    identify(data.id, { sub: data.sub, capabilities: data.capabilities });
    Sentry.setUser({
      email: data?.email,
      id: data?.id,
    });
  }, [data]);

  return null;
};

export default IdentifyManager;
